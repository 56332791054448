"use client";

import { useEffect, useState } from "react";

type Props = {};
export function useToken() {
  const getToken = () => {
    const tokenString: any =
      typeof window !== "undefined" ? sessionStorage.getItem("token") : null;
    const userToken = JSON.parse(tokenString);

    return userToken;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken: any) => {
    sessionStorage.setItem("token", JSON.stringify(userToken));
    setToken(userToken.token);
  };

  return {
    setToken: saveToken,
    token,
  };
}
export const getParams = (url: string) => {
  var params: any = {};
  var parser = document.createElement("a");
  parser.href = url;
  var query = parser.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    params[pair[0]] = decodeURIComponent(pair[1]);
  }

  return params;
};

export function isEmpty(obj: any) {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
}
const UtmHandler = (props: Props) => {
  const { setToken } = useToken();
  useEffect(() => {
    let paramList = getParams(window.location.href);

    const allowed = ["utm_campaign", "gclid"];

    const filtered = Object.keys(paramList)
      .filter((key) => allowed.includes(key))
      .reduce((obj: any, key) => {
        obj[key] = paramList[key];
        return obj;
      }, {});

    if (!isEmpty(filtered)) {
      setToken(paramList);
    } else {
      //console.log("Not campaign");
    }
  }, [typeof window !== "undefined" && window.location, setToken]);
  return null;
};

export default UtmHandler;
