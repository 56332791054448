"use client";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Button } from "@/components/ui/button";
import { BeatLoader } from "react-spinners";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  // FormLabel,
  FormMessage,
} from "@/components/ui/form";
// import { Input } from "@/components/ui/input";
import { z } from "zod";
import { AnimatePresence, motion } from "framer-motion";
import { cn, getCurrentUrlWithoutProtocol } from "@/lib/utils";
import { memo, useEffect, useState } from "react";
import { useToken } from "@/components/atoms/UtmHandler";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
type Props = {
  sendMessage: (newMessage: any) => void;
  messageIndex: number;
  removeForm: () => void;
  sendAfterForm: (values: any) => void;
  messages: any;
};
const formSchema = z.object({
  name: z.string().min(2, {
    message: "Name should be at least 2 characters long.",
  }),
  company: z.string().min(2, {
    message: "Company should be at least 2 characters long.",
  }),
  email: z.string().email({
    message: "Please enter a valid email address.",
  }),
  phone: z.string().min(10, {
    message: "Phone should be at least 10 characters long.",
  }),
});
const ChatForm = (props: Props) => {
  const { removeForm, sendAfterForm, messages } = props;
  const notify = () =>
    toast.error(
      <div>
        <p>Oops! Something went wrong.</p>
        <small>Please try again in a few minutes.</small>
      </div>,
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
      }
    );
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
    },
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formValues, setFormValues]: any = useState();
  const [loading, setIsLoading] = useState(false);
  const { token } = useToken();
  let currentUrl = getCurrentUrlWithoutProtocol();
  function onSubmit(values: z.infer<typeof formSchema>) {
    // Do something with the form values.
    // ✅ This will be type-safe and validated.
    let comments = "";
    for (let key in values) {
      if (key !== "requestDemo") {
        const valueKey = key as keyof typeof values;
        comments += `${key}: ${values[valueKey]}\n`;
      }
    }
    let filteredMessages = messages.filter((message: any) => {
      return message.isUser && message.isSelected;
    });
    for (let key in filteredMessages) {
      comments += `User replies: ${filteredMessages[key].message}\n`;
    }

    let newData: any = {
      email: values.email ? values.email : "",
      firstname: values.name ? values.name : "",
      lastname: "N/A",
      memo: comments,
      phone: values.phone ? values.phone : "00000000",
      website: "106",
      custbody_esc_campaign_category: -4,
      custbodyabco_campaign_name: "N/A",
      requestDemo: false,
      url: currentUrl,
    };

    if (token !== null) {
      if (token["utm_campaign"]) {
        newData["custbodyabco_campaign_name"] = token["utm_campaign"];
        newData["custbody_esc_campaign_category"] = -11;
      }
      if (token["gclid"]) {
        newData["custbody_esc_campaign_category"] = -15;
      }
    }
    fetch(
      "https://6763524-sb2.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=3368&deploy=1&compid=6763524_SB2&ns-at=AAEJ7tMQ78nFVV1hfo0N29vYjqvAXm5fL2AVK8GRSQDB97yO6VI&action=createOpportunity",
      // "https://6763524.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=3368&deploy=1&compid=6763524&ns-at=AAEJ7tMQscoLnHY3TQgUcIofUDhsW0KGKedD0ocvSoJn0oybK3M&action=createOpportunity",
      {
        method: "POST",
        body: JSON.stringify(newData),
      }
    )
      .then(async (res) => {
        let content = await res.json();
        if (content.data.status !== 200) {
          return notify();
        }
        setIsSubmitted(true);
        sendAfterForm(values);
      })
      .catch((err) => {
        console.log("ERROR!", err);
        return notify();
      });
  }

  useEffect(() => {
    if (isSubmitted) {
      removeForm();
    }
  }, [isSubmitted]);

  return (
    <motion.div
      layout
      initial={{ opacity: 0, scale: 1, x: 0 }}
      animate={{ opacity: 1, scale: 1, x: 0 }}
      exit={{ opacity: 0, x: 0 }}
      transition={{
        duration: 1,
        // delay:
        //   index < 3 && message.questionId === "q1" ? index * 0.3 : 0.3,
        staggerChildren: 0.5,
        type: "spring",
      }}
      className={cn(
        "flex relative flex-col gap-2 py-2 group   whitespace-pre-wrap items-end"
      )}
    >
      <ToastContainer />
      <Form {...form}>
        {loading ? (
          <AnimatePresence>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ delay: 1 }}
              layoutId="loader"
              className="flex justify-center items-center"
            >
              <BeatLoader color="#fff" />
            </motion.div>
          </AnimatePresence>
        ) : (
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="w-full max-w-[768px] space-y-4 py-4 px-1 "
          >
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <div className="relative">
                      <input
                        type="text"
                        className="block rounded-2xl px-6 pb-5 pt-4 w-full text-h4 text-white bg-transparent  border border-white/50 appearance-none focus:outline-none focus:ring-0 focus:border-white peer"
                        placeholder=" "
                        {...field}
                      />
                      <label className="absolute pointer-events-none  text-h4 text-gray-500  duration-300 transform -translate-y-6 scale-[35%] top-4 z-10 origin-[0] start-6 peer-focus:text-white  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-[35%] peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto font-semibold">
                        Name
                      </label>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="company"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <div className="relative">
                      <input
                        type="text"
                        className="block rounded-2xl px-6 pb-5 pt-4 w-full text-h4 text-white bg-transparent  border border-white/50 appearance-none focus:outline-none focus:ring-0 focus:border-white peer"
                        placeholder=" "
                        {...field}
                      />
                      <label className="absolute pointer-events-none  text-h4 text-gray-500  duration-300 transform -translate-y-6 scale-[35%] top-4 z-10 origin-[0] start-6 peer-focus:text-white  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-[35%] peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto font-semibold">
                        Company
                      </label>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <div className="relative">
                      <input
                        type="text"
                        className="block rounded-2xl px-6 pb-5 pt-4 w-full text-h4 text-white bg-transparent  border border-white/50 appearance-none focus:outline-none focus:ring-0 focus:border-white peer"
                        placeholder=" "
                        {...field}
                      />
                      <label className="absolute pointer-events-none  text-h4 text-gray-500  duration-300 transform -translate-y-6 scale-[35%] top-4 z-10 origin-[0] start-6 peer-focus:text-white  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-[35%] peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto font-semibold">
                        Email
                      </label>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="phone"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <div className="relative">
                      <input
                        type="text"
                        className="block rounded-2xl px-6 pb-5 pt-4 w-full text-h4 text-white bg-transparent  border border-white/50 appearance-none focus:outline-none focus:ring-0 focus:border-white peer"
                        placeholder=" "
                        {...field}
                      />
                      <label className="absolute pointer-events-none  text-h4 text-gray-500  duration-300 transform -translate-y-6 scale-[35%] top-4 z-10 origin-[0] start-6 peer-focus:text-white  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-[35%] peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto font-semibold">
                        Phone
                      </label>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex">
              <Button
                className="ml-auto"
                variant={"primary-light"}
                size={"xl"}
                type="submit"
              >
                Submit
                {/* {loading ? (
                <div className="flex justify-center items-center">
                  <RevolvingDot
                    visible={true}
                    height="12"
                    width="12"
                    color="#4fa94d"
                    ariaLabel="revolving-dot-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </div>
              ) : (
                "Submit"
              )} */}
              </Button>
            </div>
          </form>
        )}
      </Form>
    </motion.div>
  );
};

export default ChatForm;
